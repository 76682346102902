import { Select } from 'antd';
import type { Device } from '../../../api';
import { useState } from 'react';

interface DeploymentDevicesSelectProps {
  loading: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  onSearch: (searchValue: string) => boolean;
  sortedDevices: Device[];
}

const DeploymentDevicesSelect: React.FC<DeploymentDevicesSelectProps> = ({ loading, onChange, value, onSearch, sortedDevices }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSearch = (searchValue: string) => {
    const isValidPasteSearch = onSearch(searchValue);

    if (isValidPasteSearch) {
      setIsDropdownOpen(false);
    }
  };

  const handleOnChange = (searchValue: string[]) => {
    setIsDropdownOpen(false);
    onChange(searchValue);
  };

  return (
    <Select
      onDropdownVisibleChange={setIsDropdownOpen}
      autoClearSearchValue
      mode="multiple"
      style={{ width: '100%' }}
      loading={loading}
      value={value}
      open={isDropdownOpen}
      onChange={handleOnChange}
      onSearch={handleSearch}
      placeholder="Devices"
      filterOption={(inputValue, option) => {
        return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
      }}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {sortedDevices?.map((option: Device) => {
        return (
          <Select.Option title={`${option.rds} (${option.description})`} key={option.id} value={option.id}>
            {`${option.rds} (${option.description})`}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default DeploymentDevicesSelect;
