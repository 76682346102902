import { useQuery } from '@tanstack/react-query';
import { ToolVersion } from '../../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../../../../../../domain';
import { usePermissions } from '../../../../../../../session/hooks/usePermissions';
import { SYNCING_POLL_INTERVAL } from '../../../../../../../../constants/timing';

export function useToolVersions(engineeringToolId: string) {
  const { backend } = useEngineeringBackend();
  const parsedToolId = parseInt(engineeringToolId, 10);
  const permissions = usePermissions({ toolId: engineeringToolId });
  const hasPermissions = permissions.engineeringSvc$getToolVersions;
  return useQuery<ToolVersion[], [string, string]>(
    ['engineeringToolVersions', engineeringToolId],
    async (): Promise<ToolVersion[]> => {
      const versions = await backend.queryToolVersions(engineeringToolId);

      return versions.sort((a, b) => SemanticVersion.sorter(a.version, b.version));
    },
    {
      enabled: parsedToolId > -1 && hasPermissions,
      refetchInterval: (data) => {
        const hasInconsistent = data?.some((d) => d.state !== 'consistent');
        return hasInconsistent ? SYNCING_POLL_INTERVAL : false;
      }
    }
  );
}
