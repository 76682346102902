import React, { useMemo } from 'react';
import type { Project } from '../../../api/engineering/domain/types';
import { allSolutionTypeKey } from '../../projects/hooks/useSelectedProjectSolutionType';
import { useInAppNavigate } from '../../navigation/hooks';
import styled from 'styled-components';
import { Col, MenuProps, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ProjectPin } from '../../projects/components/ProjectPin';
import { useDeploymentEnvironments } from '../../deployments/hooks/useDeploymentEnvironments';
import { usePermissions } from '../../session';
import { useSources } from '../../reports';
import useBundles from '../../bundles/hooks/useBundles';
import useConfigurations from '../../ProjectSoftware/Configurations/hooks/useConfigurations';

type MenuItem = Required<MenuProps>['items'][number];

const FlexRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledMenuLink = styled(Link)`
  color: inherit;
  outline: 0;
  width: 100%;
`;

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  link: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  onTitleClick?: () => void
): MenuItem => {
  return {
    key,
    icon,
    children,
    label: <StyledMenuLink to={link}>{label}</StyledMenuLink>,
    type,
    onTitleClick
  } as MenuItem;
};

export const useProjectsMenuItems = (
  selectableProjects: Project[],
  solutionType: string | null,
  projectType: string | undefined,
  search: string,
  active: string | null | undefined,
  setActive: (id: string) => void
) => {
  const navigate = useInAppNavigate();
  const { data: environments } = useDeploymentEnvironments(Number(active), !!active);
  const bundles = useBundles(String(active));
  const projectSoftwareBundleId = bundles.data?.find((bundle) => bundle.name.includes('Project Software'))?.idBundle.toString() || '';
  const projectSoftwareConfigurations = useConfigurations(String(active), projectSoftwareBundleId).data;
  const permissions = usePermissions({ projectId: active || undefined });
  const { data: sources } = useSources(active ? Number(active) : -1);
  const hasReportPermissions = permissions.reportSvc$getReportSources && permissions.reportSvc$getReportRevisions;
  const hasAisForcePermissions = permissions.pAisForceSvc$calculateFlexibleConductor || permissions.pAisForceSvc$calculateRigidCylindricalConductor;
  const pdpTarget = sources?.find((s) => s.name === 'pam-pdp');
  const hasTarget = pdpTarget !== undefined;

  const solutionTypeFilteredProjects = useMemo(
    () =>
      selectableProjects
        .filter((p) => {
          if (solutionType === 'archived') {
            return p.isArchived;
          }
          if (p.isArchived) return false;
          if (!solutionType) return true;
          if (solutionType === allSolutionTypeKey) return true;
          return p.projectSolutionType.id.toString() === solutionType;
        })
        .filter((p) => {
          if (!search) return true;
          return p.name.toLowerCase().includes(search.toLowerCase());
        }),
    [selectableProjects, search, solutionType]
  );

  const items: MenuItem[] = useMemo(
    () =>
      solutionTypeFilteredProjects.map((stp) => {
        const label = (
          <FlexRow wrap={false} id={`project-menu-entry-${stp.idProject}`}>
            <Col flex="auto">
              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: 4 }}>{stp.name}</div>
            </Col>
            <Col flex="24px">
              <ProjectPin projectId={stp.idProject.toString()} />
            </Col>
          </FlexRow>
        );

        const getConfigurations = () => {
          return String(stp.idProject) === active
            ? projectSoftwareConfigurations
                ?.map((config) => ({
                  key: `/projects/${stp.idProject}/project-software/configurations/${config.idBundleConfiguration}`,
                  label: (
                    <StyledMenuLink
                      key={config.name}
                      to={`/projects/${stp.idProject}/project-software/configurations/${config.idBundleConfiguration}?active=${active}&type=${projectType}`}
                    >
                      {config.name}
                    </StyledMenuLink>
                  ),
                  onClick: () => {
                    navigate(`/projects/${stp.idProject}/project-software/configurations/${config.idBundleConfiguration}?active=${active}&type=${projectType}`);
                  }
                }))
                .sort((a, b) => a.label.key!.toLowerCase().localeCompare(b.label.key!.toLowerCase())) || []
            : [];
        };

        const envChildren =
          String(stp.idProject) === active
            ? environments?.map((env) => ({
                key: `/projects/${stp.idProject}/deployments/${env.id}`,
                label: <StyledMenuLink to={`/projects/${stp.idProject}/deployments/${env.id}?active=${active}&type=${projectType}`}>{env.name}</StyledMenuLink>,
                onClick: () => {
                  navigate(`/projects/${stp.idProject}/deployments/${env.id}?active=${active}&type=${projectType}`);
                }
              })) || []
            : [];

        const children = [
          {
            key: `/projects/${stp.idProject}/project-software/configurations`,
            label: (
              <StyledMenuLink to={`/projects/${stp.idProject}/project-software/configurations?active=${active}&type=${projectType}`}>
                Project software
              </StyledMenuLink>
            ),
            onTitleClick: () => {
              navigate(`/projects/${stp.idProject}/project-software/configurations?active=${active}&type=${projectType}`);
            },
            children: getConfigurations()
          },
          {
            key: `/projects/${stp.idProject}/deployments/environments`,
            label: (
              <StyledMenuLink to={`/projects/${stp.idProject}/deployments/environments?active=${active}&type=${projectType}`}>Environments</StyledMenuLink>
            ),
            onTitleClick: () => {
              navigate(`/projects/${stp.idProject}/deployments/environments?active=${active}&type=${projectType}`);
            },
            children: envChildren
          },
          hasAisForcePermissions
            ? {
                key: `/projects/${stp.idProject}/ais-force`,
                label: <StyledMenuLink to={`/projects/${stp.idProject}/ais-force?active=${active}&type=${projectType}`}>AIS Force</StyledMenuLink>,
                onClick: () => {
                  navigate(`/projects/${stp.idProject}/ais-force?active=${active}&type=${projectType}`);
                }
              }
            : null,
          hasTarget && hasReportPermissions
            ? {
                key: `/projects/${stp.idProject}/reports/${pdpTarget?.id}/latest`,
                label: (
                  <StyledMenuLink to={`/projects/${stp.idProject}/reports/${pdpTarget?.id}/latest?active=${active}&type=${projectType}`}>
                    Plant Data Backbone
                  </StyledMenuLink>
                ),
                onClick: () => {
                  navigate(`/projects/${stp.idProject}/reports/${pdpTarget?.id}/latest?active=${active}&type=${projectType}`);
                }
              }
            : null,
          {
            key: `/projects/${stp.idProject}/members`,
            label: <StyledMenuLink to={`/projects/${stp.idProject}/members?active=${active}&type=${projectType}`}>Members</StyledMenuLink>,
            onClick: () => {
              navigate(`/projects/${stp.idProject}/members?active=${active}&type=${projectType}`);
            }
          }
        ];

        return getItem(label, stp.idProject, `/projects?active=${stp.idProject}`, undefined, children, undefined, () => {
          navigate(`/projects?active=${active}&type=${projectType}`);
          setActive(String(stp.idProject));
        });
      }),
    [
      active,
      environments,
      hasAisForcePermissions,
      hasReportPermissions,
      hasTarget,
      pdpTarget,
      projectSoftwareConfigurations,
      projectType,
      setActive,
      solutionTypeFilteredProjects,
      navigate
    ]
  );

  return items;
};
