import { useQuery } from '@tanstack/react-query';
import { DeploymentPlan, useDeploymentBackend } from '../../../api';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const useLatestDeploymentPlanOfTheProject = (projectId: number) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({
    projectId: String(projectId)
  });

  return useQuery<DeploymentPlan | null, [string, string]>(
    ['latestDeploymentPlan', projectId],
    async (): Promise<DeploymentPlan | null> => {
      return (await backend.getLatestDeploymentPlanOfTheProject(projectId)).latest || null;
    },
    {
      enabled: projectId > -1 && permissions.deploymentSvc$getLatestDeploymentPlanOfTheProject
    }
  );
};
