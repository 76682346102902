import { lazy, Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useProject } from '../hooks';
import { aisForceConfig } from '@p-apps/ais-force/config';
import { PageLoading } from '@pacts-plugin-api';
import ProjectContentWrapper from './ProjectContentWrapper';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
const AISForce = lazy(() => import('@p-apps/ais-force'));

const labelByEnding: Record<string, any> = {
  '/ais-force': 'AIS Force',
  '/flexible-conductor': { baseUrl: 'ais-force', label: 'AIS Force', subLabel: 'Flexible Conductor' },
  '/rigid-cylindrical-conductor': { baseUrl: 'ais-force', label: 'AIS Force', subLabel: 'Rigid Cylindrical Conductor' },
  '/saved': { baseUrl: 'ais-force', label: 'AIS Force', subLabel: 'Project Calculations' },
  '/rigid': { baseUrl: 'ais-force', label: 'AIS Force', subLabel: 'Rigid Cylindrical Conductors' },
  '/flexible': { baseUrl: 'ais-force', label: 'AIS Force', subLabel: 'Flexible Conductors' }
};

export const ProjectAISForce = () => {
  const params = useParams();
  const location = useLocation();
  const [projectType] = useSearchParameter('type');
  const project = useProject(params?.projectId || '');
  const activeKey = Object.keys(labelByEnding).find((key) => location.pathname?.endsWith(key)) || '';
  const activeLabel: string | null = typeof labelByEnding[activeKey] === 'object' ? labelByEnding[activeKey]?.label : labelByEnding[activeKey] || null;
  const activeLabelUrl: string | null = typeof labelByEnding[activeKey] === 'object' ? labelByEnding[activeKey]?.baseUrl : null;
  const activeSubLabel: string | null = typeof labelByEnding[activeKey] === 'object' ? labelByEnding[activeKey]?.subLabel : null;
  const projectName = project.data?.name;

  const breadcrumbItems = [
    ...(projectName
      ? [
          {
            title: projectName,
            url: `/projects?active=${project.data?.idProject}&type=${projectType}`
          }
        ]
      : []),
    ...(activeLabel
      ? [
          {
            title: activeLabel,
            url: activeLabelUrl ? `/projects/${params?.projectId}/${activeLabelUrl}?active=${params?.projectId}&type=${projectType}` : undefined
          }
        ]
      : []),
    ...(activeSubLabel
      ? [
          {
            title: activeSubLabel
          }
        ]
      : [])
  ];

  return (
    <Suspense fallback={<PageLoading />}>
      <ProjectContentWrapper
        breadcrumbItems={breadcrumbItems}
        title={
          (
            <span>
              {project.data?.name || ''} <small>{aisForceConfig.name}</small>
            </span>
          ) as unknown as string
        }
      >
        <AISForce />
      </ProjectContentWrapper>
    </Suspense>
  );
};
