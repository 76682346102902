import { useState, useEffect } from 'react';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SemanticVersion } from '../../../../../../../domain';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';
import { SoftwareAppVersionForm } from './SoftwareAppVersionForm';
import { useCreateCommonSoftwareAppVersion } from './hooks/useCreateCommonSoftwareAppVersion';
import { SoftwareApp, SoftwareAppVersion, SoftwareAppVersionCreate } from '../../../../../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../../../../../shared/components/ShiftedDrawer';
import { cloneDeep } from 'lodash';

export const AddCommonSoftwareAppVersion = (props: { parentApp: SoftwareApp; existingSoftwareAppVersion?: SoftwareAppVersion; detailView?: boolean }) => {
  const permissions = usePermissions({ softwareAppId: props.parentApp.idSoftwareApp.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const createSoftwareAppVersion = useCreateCommonSoftwareAppVersion();
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  const getInitialSwAppVersion = () => {
    if (props.existingSoftwareAppVersion) {
      const next: SoftwareAppVersionCreate = cloneDeep(props.existingSoftwareAppVersion);
      next.releaseNotes = '';
      next.version = SemanticVersion.fromUnsafeString(props.existingSoftwareAppVersion.version).increment().toString({ hideSuffix: true });
      next.targets?.forEach((t) => {
        t.downloadLink = '';
        t.sha256 = undefined;
        t.icsPortalComponentId = undefined;
      });
      return next;
    }
    return {
      releaseNotes: '',
      version: '',
      targets: []
    } as SoftwareAppVersionCreate;
  };

  useEffect(() => {
    if (createSoftwareAppVersion.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [createSoftwareAppVersion.isSuccess, form]);

  const handleCancel = () => {
    resetHandler();
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      {permissions.engineeringSvc$addCommonSoftwareAppVersion && (
        <Button
          icon={<PlusOutlined />}
          loading={createSoftwareAppVersion.isLoading}
          onClick={() => {
            setModalVisible(true);
            form.setFieldsValue(getInitialSwAppVersion());
          }}
          type="default"
          style={props.detailView ? { float: 'right' } : {}}
        >
          New version
        </Button>
      )}

      <ShiftedDrawer
        title="New version"
        destroyOnClose
        loading={createSoftwareAppVersion.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={handleCancel}
      >
        <SoftwareAppVersionForm
          initial={getInitialSwAppVersion()}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          parentApp={props.parentApp}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              createSoftwareAppVersion
                .mutateAsync([props.parentApp.idSoftwareApp.toString(), ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ShiftedDrawer>
    </>
  );
};
