import { Checkbox as AntCheckbox, Flex, Form as AntForm, FormInstance, Typography } from 'antd';
import DeploymentPlanFormAlert from './DeploymentPlanFormAlert';
import { FormProps } from 'antd/lib/form';
import { DeploymentPlanPrototype } from './DeploymentPlanForm';
import { UseQueryResult } from '@tanstack/react-query';
import { Device, Tool } from '../../../api';
import styled from 'styled-components';
import { ScopedSoftwareApp } from '../../ProjectSoftware/ConfigurationDetails/types';
import { softwareItemHash } from '../../ProjectSoftware/ConfigurationDetails/utils/softwareItemHash';
import CopyButton from './CopyButton';
import { useMemo } from 'react';
import DeploymentDevicesSelect from './DeploymentDevicesSelect';
import PasteButton from './PasteButton';

interface DeploymentSelectionFormProps {
  formItemLayout: Partial<FormProps>;
  setSubmitValue: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  redirectToFollowingStep: () => void;
  secondFormInitials: DeploymentPlanPrototype | undefined;
  secondForm: FormInstance<DeploymentPlanPrototype>;
  selectedComponents: string[];
  isSecondFormAlert: boolean;
  setSelectedComponents: React.Dispatch<React.SetStateAction<string[]>>;
  toggleAlertCheck: (formErrors: Array<{ errors?: string[] }>) => void;
  devices: UseQueryResult<Device[], unknown>;
  sortedDevices: Device[];
}

const Form = styled(AntForm)`
  .ant-form-item-label {
    overflow: visible !important;
  }
` as typeof AntForm;

const Checkbox = styled(AntCheckbox)`
  margin-right: 15px;
  ${(props) =>
    !props.checked
      ? `
    .ant-checkbox-inner {
      border-color: ${props.theme.colorWarning};
    }
  `
      : ''};
`;

const StyledFormItem = styled(Form.Item)`
  /* Compensate for form height difference when validation error is shown */
  .ant-form-item-margin-offset {
    margin-bottom: -6px !important;
  }
`;

const SelectionFormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const DeploymentSelectionForm: React.FC<DeploymentSelectionFormProps> = ({
  formItemLayout,
  setSubmitValue,
  redirectToFollowingStep,
  secondFormInitials,
  secondForm,
  selectedComponents,
  isSecondFormAlert,
  setSelectedComponents,
  toggleAlertCheck,
  devices,
  sortedDevices
}) => {
  const deviceIds = useMemo(() => devices.data?.map((device) => device.id), [devices.data]);

  const handleCopySelection = (selectValues: string[]) => {
    const joinedValues = selectValues.join(',');
    navigator.clipboard.writeText(joinedValues);
  };

  return (
    <Form
      {...formItemLayout}
      labelAlign="left"
      colon={false}
      name="basic"
      onFinish={async (value: Record<string, any>) => {
        setSubmitValue((prev) => ({
          ...prev,
          ...value
        }));

        redirectToFollowingStep();
      }}
      initialValues={{ ...secondFormInitials }}
      form={secondForm}
    >
      <Form.List name={['deployments']}>
        {(fields) => (
          <>
            {!selectedComponents.length && <DeploymentPlanFormAlert message="At least one software must be selected." />}
            {isSecondFormAlert && (
              <DeploymentPlanFormAlert message="Before proceeding to the next step, please make sure all mandatory fields are completed." />
            )}
            {fields.map((field) => {
              const softwareItem = secondForm?.getFieldValue(['deployments', field.name, 'selection', 'softwareItem']) as ScopedSoftwareApp | Tool;
              const softwareHash = softwareItemHash(softwareItem);
              const isChecked = selectedComponents.includes(softwareHash);
              const deviceFieldValue = secondForm?.getFieldValue(['deployments', field.name, 'device']) as Device[];

              // Due to useForm functionality there might be a case when the multiselect field input is set but no values are selected,
              // for that particular case we need to handle the case when it returns a string instead of an array with selected values
              const selectValues = typeof deviceFieldValue === 'string' ? [] : deviceFieldValue?.map((d) => d.id) || [];

              const handlePasteIntoSelect = (searchValue: string, currentSelectionIds: string[]) => {
                const additionalSelection = searchValue.split(',');
                const isAdditionalSelectionValid = additionalSelection.every((selectionDeviceId) => deviceIds?.includes(selectionDeviceId));

                if (isAdditionalSelectionValid) {
                  const filteredAdditionalSelection = additionalSelection.filter((selectionDeviceId) => !currentSelectionIds.includes(selectionDeviceId));
                  const combinedDevicesSelection = [...currentSelectionIds, ...filteredAdditionalSelection].map(
                    (selectionDeviceId) => devices.data?.find((device) => device.id === selectionDeviceId)
                  );
                  // This setTimeout ensures that Ant Design's internal search handling completes before overriding the value
                  setTimeout(() => {
                    const formValue = secondForm.getFieldsValue();
                    formValue.deployments[field.name].device = combinedDevicesSelection as Device[];
                    secondForm.setFieldsValue(formValue);
                  }, 0);
                }

                return isAdditionalSelectionValid;
              };

              const handlePasteClick = async (pasteSelectValues: string[]) => {
                try {
                  const text = await navigator.clipboard.readText();
                  handlePasteIntoSelect(text, pasteSelectValues);
                } catch (err) {
                  console.error('Failed to read clipboard: ', err);
                }
              };

              return (
                <StyledFormItem
                  label={
                    <Flex align="center" style={{ overflowX: 'hidden', width: '300px' }}>
                      <Checkbox
                        checked={isChecked}
                        onChange={(e) => {
                          const newChecked = e.target.checked;

                          if (newChecked) {
                            setSelectedComponents((prev) => [...prev, softwareHash]);
                          } else {
                            setSelectedComponents((prev) => prev.filter((val) => val != softwareHash));
                          }
                          secondForm
                            .validateFields()
                            .then(() => {
                              toggleAlertCheck([]);
                            })
                            .catch((errorFields) => {
                              toggleAlertCheck(errorFields);
                            });
                        }}
                      />
                      <div style={{ overflow: 'hidden' }}>
                        <Typography.Text style={{ width: '100%', textOverflow: 'ellipsis' }} ellipsis={{ tooltip: softwareItem.name }}>
                          {softwareItem.name}
                        </Typography.Text>
                        <Typography.Text type="secondary" ellipsis={{ tooltip: softwareItem.latestVersion!.version }}>
                          {softwareItem.latestVersion!.version}
                        </Typography.Text>
                      </div>
                    </Flex>
                  }
                  key={field.key}
                  rules={[
                    {
                      required: isChecked,
                      message: 'Device is Required'
                    }
                  ]}
                  required
                  name={[field.name, 'device']}
                >
                  {isChecked ? (
                    <SelectionFormItem>
                      <DeploymentDevicesSelect
                        sortedDevices={sortedDevices}
                        loading={devices.isLoading}
                        value={selectValues}
                        onChange={(allSelected) => {
                          const formValue = secondForm.getFieldsValue();
                          const formErrors = secondForm.getFieldsError();
                          const isErrorsPropagated = formErrors.some((err) => err.errors.length);
                          const device = allSelected.map((deviceId) => sortedDevices?.find((i) => i.id === deviceId)!);
                          if (device) {
                            formValue.deployments[field.name].device = device;
                            secondForm.setFieldsValue({ ...formValue });

                            if (isErrorsPropagated) {
                              secondForm
                                .validateFields()
                                .then(() => {
                                  toggleAlertCheck([]);
                                })
                                .catch((errorFields) => {
                                  toggleAlertCheck(errorFields);
                                });
                            }
                          }
                        }}
                        onSearch={(searchValue) => handlePasteIntoSelect(searchValue, selectValues)}
                      />
                      <CopyButton onClick={() => handleCopySelection(selectValues)} disabled={selectValues.length < 1} />
                      <PasteButton onClick={() => handlePasteClick(selectValues)} />
                    </SelectionFormItem>
                  ) : (
                    <Typography.Text strong type="warning">
                      Will not be deployed.
                    </Typography.Text>
                  )}
                </StyledFormItem>
              );
            })}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default DeploymentSelectionForm;
