import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';

interface CopyButtonProps {
  onClick: () => void;
  tooltipText?: string;
  disabled?: boolean;
}

const StyledButton = styled(Button)`
  padding: 0 8px;
`;

const CopyButton: React.FC<CopyButtonProps> = ({ onClick, disabled = false, tooltipText }) => {
  const [isCopied, setIsCopied] = useState(false);
  const tooltipTitle = isCopied
    ? 'Copied!'
    : tooltipText || 'Copy all the devices associated with this software. To further paste them, select the destination dropdown and press ctrl+v';
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopyText = () => {
    onClick();
    setIsCopied(true);

    // Clear previous timeout if existent
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 750);
  };

  return (
    <Tooltip title={tooltipTitle}>
      <StyledButton onClick={handleCopyText} disabled={disabled}>
        {isCopied ? <CheckOutlined /> : <CopyOutlined />}
      </StyledButton>
    </Tooltip>
  );
};

export default CopyButton;
