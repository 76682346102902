import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Project, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../../../api/engineering/domain/types';
import { SoftwareItemSelection } from '../../../../../../../domain/softwareAppsSelections';
import { ScopedSoftwareApp } from '../../../../types';
import { getSelectionMapKey, SelectionMap, SoftwareTable } from './SoftwareTable';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { Comparator } from '../../../../../../../domain';
import { useLocalStorageState } from '../../../../../../shared/hooks/useLocalStorageState';
import { useSearchParameter } from '../../../../../../navigation/hooks/useSearchParameter';

const prefferedCategoryListKey = 'preferred-app-category-list-key';
const allCategoriesLabel = 'All Categories';

export const SoftwareTableProvider = (props: {
  project?: Project;
  selected: SoftwareItemSelection[];
  initiallySelected: SoftwareItemSelection[];
  softwareItems: (ScopedSoftwareApp | Tool)[];
  showBundleItemsOnly?: boolean;
  hideCheckboxes?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareItemSelection[]) => any;
  readonly?: boolean;
}) => {
  const [filteredItems, setFilteredItems] = useState([] as (ScopedSoftwareApp | Tool)[]);
  const [preferredTabKey, setPrefferedTabKey] = useLocalStorageState<string>(prefferedCategoryListKey, undefined);
  const [activeTabKey, setActiveTabKey] = useSearchParameter('tab', preferredTabKey || undefined);
  const selectionMap = useRef({} as SelectionMap);

  // the initial selection map is read-only
  // it can be generated from the initially selected list
  const initialAsSelectionMap: SelectionMap = useMemo(() => {
    const map: SelectionMap = {};
    props.initiallySelected.forEach((initialSelection) => {
      map[getSelectionMapKey(initialSelection.softwareItem)] = initialSelection;
    });
    return map;
  }, [props.initiallySelected]);

  const getCategoryName = (softwareItem: ScopedSoftwareApp | Tool): string => {
    return (softwareItem as Tool).category
      ? 'Tools'
      : (softwareItem as ScopedSoftwareApp).categories && (softwareItem as ScopedSoftwareApp).categories.length > 0
        ? (softwareItem as ScopedSoftwareApp).categories[0].name
        : 'unknown';
  };

  const findSoftwareItemInSelected = (selectedItems: SoftwareItemSelection[], softwareItem: ScopedSoftwareApp | Tool) => {
    return selectedItems.find((softwareItemSelection) =>
      (softwareItemSelection.softwareItem as ScopedSoftwareApp).idSoftwareApp
        ? (softwareItemSelection.softwareItem as ScopedSoftwareApp).idSoftwareApp === (softwareItem as ScopedSoftwareApp).idSoftwareApp &&
          (softwareItemSelection.softwareItem as ScopedSoftwareApp).scope === (softwareItem as ScopedSoftwareApp).scope
        : (softwareItemSelection.softwareItem as Tool).id === (softwareItem as Tool).id
    );
  };

  const getSoftwareItemSelectionKey = (softwareItemSelection: SoftwareItemSelection) => {
    return (softwareItemSelection.softwareItem as ScopedSoftwareApp).idSoftwareApp
      ? (softwareItemSelection.version as SoftwareAppVersion).idSoftwareAppVersion + (softwareItemSelection.softwareItem as ScopedSoftwareApp).scope
      : (softwareItemSelection.version as ToolVersion).idToolVersion;
  };

  // update selection map based on selected
  const updateSelectionMap = useCallback(() => {
    props.selected.forEach((t) => {
      if (selectionMap.current[getSelectionMapKey(t.softwareItem)]) {
        selectionMap.current[getSelectionMapKey(t.softwareItem)].version = t.version;
      }
    });
  }, [props.selected]);

  // synchronize apps prop with selection map
  const synchronizeSelectionMaps = useCallback(() => {
    // filter apps which are not included in selection Map
    // create initial entry
    props.softwareItems
      .filter((softwareItem) => !selectionMap.current[getSelectionMapKey(softwareItem)])
      .forEach((softwareItem) => {
        const key = getSelectionMapKey(softwareItem);

        // by default use latest version
        selectionMap.current[key] = {
          softwareItem,
          version: softwareItem.latestVersion
        };
      });

    // delete entries from selection map
    // without corresponding app
    Object.keys(selectionMap.current)
      .filter((key) => !props.softwareItems.find((softwareItem) => getSelectionMapKey(softwareItem) === key))
      .forEach((key) => {
        delete selectionMap.current[key];
      });
  }, [props.softwareItems]);

  // synchronize selection map with available apps
  useEffect(() => {
    // synchronize selection maps
    synchronizeSelectionMaps();

    // update selection maps
    updateSelectionMap();

    // trigger this synchronizing effect whenever either the synchronizer
    // or one of the selection update callbacks changes
  }, [synchronizeSelectionMaps, updateSelectionMap]);

  const groupedSoftwareItems = useMemo(() => {
    let computedSoftwareItems: Record<string, (ScopedSoftwareApp | Tool)[]> = {
      [allCategoriesLabel]: filteredItems
    };

    if (props.softwareItems) {
      computedSoftwareItems = {
        ...computedSoftwareItems,
        ...filteredItems.reduce(
          (rv, x) => {
            const category = getCategoryName(x);
            (rv[category] = rv[category] || []).push(x);
            return rv;
          },
          {} as Record<string, (ScopedSoftwareApp | Tool)[]>
        )
      };
    }

    return computedSoftwareItems;
  }, [props.softwareItems, filteredItems]);

  useEffect(() => {
    if (!props.onDirty) {
      return;
    }

    const selectedVersionHashes = props.selected
      .map((selected) => getSoftwareItemSelectionKey(selected))
      .sort()
      .join('+');

    const initiallySelectedVersionHashes = props.initiallySelected
      .map((initiallySelected) => getSoftwareItemSelectionKey(initiallySelected))
      .sort()
      .join('+');

    props.onDirty(selectedVersionHashes !== initiallySelectedVersionHashes);

    // eslint-disable-next-line
  }, [props.selected, props.initiallySelected, props.softwareItems]);

  useEffect(() => {
    setFilteredItems(
      [...props.softwareItems].filter(
        (softwareItem) =>
          findSoftwareItemInSelected(props.initiallySelected, softwareItem) ||
          findSoftwareItemInSelected(props.selected, softwareItem) ||
          !props.showBundleItemsOnly
      )
    );
  }, [props.softwareItems, props.selected, props.showBundleItemsOnly, props.initiallySelected]);

  useEffect(() => {
    if (groupedSoftwareItems[activeTabKey ?? ''] == null && Object.keys(groupedSoftwareItems).length > 1) {
      const defaultKey = Object.keys(groupedSoftwareItems)[0];

      setActiveTabKey(defaultKey);
    }
  }, [groupedSoftwareItems, activeTabKey, setActiveTabKey]);

  const onSelectionUpdate = (selectedList: SoftwareItemSelection[]) => {
    props.onSelect(selectedList.filter(Boolean));
  };

  const handleActiveTabChange = (key: SegmentedValue) => {
    const keyStr = key.toString();

    setActiveTabKey(keyStr);
    setPrefferedTabKey(keyStr);
  };

  const updateFromProps = (softwareItem: ScopedSoftwareApp | Tool) => {
    if (findSoftwareItemInSelected(props.selected, softwareItem)) {
      const selected = props.selected.map((sr) => selectionMap.current[getSelectionMapKey(sr.softwareItem as ScopedSoftwareApp | Tool)]);
      onSelectionUpdate(selected);
    } else {
      // Always update selection map to ensure rerender of the list even if target of non selected app was changed
      onSelectionUpdate([...props.selected]);
    }
  };
  const updateSelectedSoftwareAppVersion = (softwareItem: ScopedSoftwareApp | Tool, version: SoftwareAppVersion | ToolVersion) => {
    selectionMap.current[getSelectionMapKey(softwareItem)] = {
      softwareItem,
      version
    };
    updateFromProps(softwareItem);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: props.selected.map((t) => getSelectionMapKey(t.softwareItem as ScopedSoftwareApp | Tool)),
      onChange: (selectedRowKeys: string[], selectedRows: (ScopedSoftwareApp | Tool)[]) => {
        // Get all elements in currently selected tab
        const keysOfCurrentTab = groupedSoftwareItems[activeTabKey ?? allCategoriesLabel].map(getSelectionMapKey);
        // Remove all elements of current tab from selection list
        const newSelectedItems = props.selected.filter(
          (softwareItemSelection) => !keysOfCurrentTab.includes(getSelectionMapKey(softwareItemSelection.softwareItem as ScopedSoftwareApp | Tool))
        );
        // Get elements from selection map
        const toPush = selectedRows.map((sr) => selectionMap.current[getSelectionMapKey(sr)]);
        // Add back to selected elements
        newSelectedItems.push(...toPush);
        onSelectionUpdate(newSelectedItems);
      },
      getCheckboxProps: (record: any) => ({
        name: record.name
      })
    };
  };

  // Disabled numbering until we get a proper specification
  // TODO: enable once we know how it is supposed to behave

  // const changes = useMemo(() => {
  //   return xorBy(props.selected, props.initiallySelected, (s) => `${getSelectionMapKey(s.app)}-${s.version.idSoftwareAppVersion}`).map((s) =>
  //     getSelectionMapKey(s.app)
  //   );
  // }, [props.selected, props.initiallySelected]);

  const tabOptions: SegmentedLabeledOption[] = useMemo(() => {
    const tabs = Object.keys(groupedSoftwareItems).map((key) => {
      // const keysOfCurrentTab = groupedSoftwareApps[key].map(getSelectionMapKey);
      // const changesInCategory = keysOfCurrentTab.filter((app) => changes.includes(app));
      // const changesCount = changesInCategory.length;
      return {
        value: key,
        label: key
      };
    });
    const sortedTabs = [tabs[0], ...tabs.slice(1).sort((a, b) => Comparator.lexicographicalComparison(a.value, b.value))];

    return sortedTabs;
  }, [groupedSoftwareItems]);

  if (activeTabKey == null) {
    return null;
  }
  if (groupedSoftwareItems[activeTabKey] == undefined) {
    return null;
  }

  return (
    <SoftwareTable
      activeTabKey={activeTabKey}
      onActiveTabChange={handleActiveTabChange}
      tabOptions={tabOptions}
      hideCheckboxes={props.hideCheckboxes}
      bundleSelectionMap={initialAsSelectionMap}
      project={props.project}
      rowSelection={rowSelection}
      selectionMap={selectionMap.current}
      softwareItems={groupedSoftwareItems[activeTabKey] ?? []}
      updateSelectedSoftwareAppVersion={updateSelectedSoftwareAppVersion}
      readonly={props.readonly}
    />
  );
};
