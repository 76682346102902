import { useQuery } from '@tanstack/react-query';
import { Bundle, BundleConfiguration, BundleReleaseLite, useEngineeringBackend } from '../../../api';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const useLatestProjectBundleConfigurationRelease = (projectId: number) => {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({
    projectId: String(projectId)
  });

  return useQuery<{ release: BundleReleaseLite; config: BundleConfiguration; bundle: Bundle } | null, [string, string]>(
    ['latestBundleRelease', projectId],
    () => backend.getLatestProjectBundleConfigurationRelease(projectId),
    {
      enabled: projectId > -1 && permissions.engineeringSvc$getLatestProjectBundleConfigurationRelease
    }
  );
};
