import { Card } from 'antd';
import { StepProps } from '../types';
import styled from 'styled-components';

const StyledStep = styled.div`
  height: 100%;
`;

const StyledStepContent = styled.div<StepProps>`
  padding: ${(props) => (props.noPadding ? '0' : '15px 20px')};
  border: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colorBorder}`)};
  height: ${(props) => (props.information ? 'calc(100% - 70px)' : '100%')};
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: 1600px) {
    padding: ${(props) => (props.noPadding ? '0' : '20px 30px')};
  }

  form {
    height: 100%;

    .ant-row {
      height: 100%;
    }
  }
`;

const StyledInformationCard = styled(Card)`
  border: ${({ theme }) => `1px solid ${theme.colorBorder}`};
  margin-top: -1px;
  display: flex;
  align-items: center;
  text-align: left;
  border-radius: 0;
  width: 100%;
  height: 70px;
`;

const Step = ({ children, information, noPadding, noBorder, className }: StepProps) => {
  return (
    <StyledStep>
      <StyledStepContent className={className} noBorder={noBorder} noPadding={noPadding} information={information}>
        {children}
      </StyledStepContent>
      {information !== undefined && <StyledInformationCard>{information ? information : ''}</StyledInformationCard>}
    </StyledStep>
  );
};

export default Step;
