import { ApiError } from '../../shared/apiError';
import {
  DeploymentPlan,
  DeploymentPlanLock,
  DeviceDelete,
  OtcCreate,
  OtcDelete,
  DeploymentPlanCreate,
  DeploymentPlanMeta,
  DeploymentPlanUpdateDeployment,
  Device,
  DeviceCreate,
  Environment,
  Otc,
  DeploymentPlanPatchDeployment
} from '../domain/deployments';

export class DeploymentBackendError extends ApiError {}

export interface DeploymentBackend {
  onError(handler: (error: DeploymentBackendError) => any): void;

  queryDeploymentPlan(projectId: number, envId: string, deploymentPlanId: string): Promise<DeploymentPlan>;
  queryDeploymentPlans(projectId: number, envId: string): Promise<DeploymentPlanMeta[]>;
  getLatestDeploymentPlanOfTheProject(projectId: number): Promise<{ latest?: DeploymentPlan }>;

  queryDevices(projectId: number, envId: string): Promise<Device[]>;
  queryOtc(projectId: number, envId: string): Promise<Otc[]>;

  createDeploymentPlan(projectId: number, envId: string, create: DeploymentPlanCreate): Promise<DeploymentPlan>;
  createDevice(projectId: number, envId: string, create: DeviceCreate): Promise<Device>;
  createOtc(projectId: number, envId: string, create: OtcCreate): Promise<Otc>;

  lockDeploymentPlan(projectId: number, envId: string, planId: string, lock: DeploymentPlanLock): Promise<DeploymentPlan>;
  updateDeploymentPlanDeployment(projectId: number, envId: string, deploymentPlanId: string, update: DeploymentPlanUpdateDeployment): Promise<DeploymentPlan>;
  patchDeploymentPlanDeployment(projectId: number, envId: string, deploymentPlanId: string, update: DeploymentPlanPatchDeployment): Promise<DeploymentPlan>;

  updateOtc(projectId: number, envId: string, otcId: string, update: Otc): Promise<Otc>;
  updateDevice(projectId: number, envId: string, deviceId: string, update: Device): Promise<Device>;

  deleteDevice(projectId: number, envId: string, deviceId: string, deviceDelete: DeviceDelete): Promise<void>;
  deleteOtc(projectId: number, envId: string, otcId: string, otcDelete: OtcDelete): Promise<void>;

  getDeploymentEnvironments(projectId: number): Promise<Environment[]>;

  addDeploymentEnvironment(projectId: number, name: string, description: string): Promise<Environment>;

  deleteDeploymentEnvironment(projectId: number, envId: string, mvccId: number): Promise<void>;

  updateDeploymentEnvironment(projectId: number, envId: string, mvccId: number, name: string, description: string): Promise<Environment>;

  getDeploymentPlanReport(
    projectId: number,
    envId: string,
    deploymentPlanId: string,
    format?: 'xlsx' | 'pdf',
    reportTitle?: boolean,
    columns?: Array<
      | 'otc'
      | 'rds'
      | 'deviceDescription'
      | 'softwareComponentName'
      | 'softwareComponentVersion'
      | 'softwareDescription'
      | 'softwareReleaseNotes'
      | 'softwareChecksum'
      | 'installationStatus'
      | 'executor'
      | 'installationDate'
      | 'installationNotes'
    >,
    withNotDeployedSoftware?: boolean,
    sign?: boolean,
    sort?: string,
    groupBy?: boolean
  ): Promise<{ streamUri: string; signature?: string }>;
}
