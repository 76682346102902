import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SoftwareAppVersion } from '../../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../../../../../../domain';
import { usePermissions } from '../../../../../../../session/hooks/usePermissions';
import { useDebounce } from '../../../../../../../shared/hooks/useDebounce';
import { SYNCING_POLL_INTERVAL } from '../../../../../../../../constants/timing';

export function useProjectSoftwareAppVersions(projectId: string, softwareAppId: string) {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  const permissions = usePermissions({ projectId, softwareAppId });

  const toDebounceConfig = useMemo(() => {
    return { projectId, softwareAppId };
  }, [projectId, softwareAppId]);

  const db = useDebounce(toDebounceConfig);
  return useQuery<SoftwareAppVersion[], [string, string, string]>(
    ['softwareAppVersions', db.projectId, db.softwareAppId],
    async (): Promise<SoftwareAppVersion[]> => {
      const versions = await backend.queryProjectAppVersions(projectId, softwareAppId);

      return versions.sort((a, b) => SemanticVersion.sorter(a.version, b.version));
    },
    {
      enabled: parsedProjectId > -1 && parsedSoftwareAppId > -1 && permissions.engineeringSvc$getProjectSoftwareAppVersions,
      refetchInterval: (data) => {
        const hasInconsistent = data?.some((d) => d.state !== 'consistent');
        return hasInconsistent ? SYNCING_POLL_INTERVAL : false;
      }
    }
  );
}
