import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApiError } from '../api/shared/apiError';
import { RETRY_DELAY, STALE_TIME } from '../constants/timing';

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

const defaultOptions = {
  queries: {
    retry: (failureCount: number, error: unknown) => {
      if (failureCount > MAX_RETRIES) {
        // retry limit exceeded
        return false;
      }

      if (HTTP_STATUS_TO_NOT_RETRY.includes((error as ApiError).statusCode ?? 0)) {
        // ignore these errors - don't retry
        return false;
      }

      return true;
    },
    retryDelay: RETRY_DELAY,
    staleTime: STALE_TIME
  }
};
const queryClient = new QueryClient({ defaultOptions });

const PactsFrontendQueryClientProvider = (props: { children: React.ReactNode; queryClient?: QueryClient }) => {
  return (
    <QueryClientProvider client={props.queryClient ? props.queryClient : queryClient} contextSharing>
      {props.children}
    </QueryClientProvider>
  );
};

export { PactsFrontendQueryClientProvider };
