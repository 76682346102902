import { CheckOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';

interface CopyButtonProps {
  onClick: () => void;
  tooltipText?: string;
  disabled?: boolean;
}

const StyledButton = styled(Button)`
  padding: 0 8px;
`;

const PasteButton: React.FC<CopyButtonProps> = ({ onClick, disabled = false, tooltipText }) => {
  const [isPasted, setIsPasted] = useState(false);
  const tooltipTitle = isPasted ? 'Pasted!' : tooltipText || 'Paste';
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopyText = () => {
    onClick();
    setIsPasted(true);

    // Clear previous timeout if existent
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsPasted(false);
    }, 750);
  };

  return (
    <Tooltip title={tooltipTitle}>
      <StyledButton onClick={handleCopyText} disabled={disabled}>
        {isPasted ? <CheckOutlined /> : <SnippetsOutlined />}
      </StyledButton>
    </Tooltip>
  );
};

export default PasteButton;
