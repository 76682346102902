import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Flex, Typography } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { formatDate } from '../../../../shared/components/formatDate';
import useDeleteConfiguration from './hooks/useDeleteConfiguration';
import useConfigurationVersions from '../../hooks/useConfigurationVersions';
import { AddEditConfiguration } from '../AddEditConfiguration/AddEditConfiguration';
import { StopEventPropagationWrapper } from '../../../../../contexts/shared/components/StopEventPropagationWrapper';
import { ExpandableMenu } from '../../../../../contexts/shared/components/ExpandableMenu';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import { ConfirmationButton } from '../../../../shared/components/ConfirmationButton';

const StyledCard = styled(Card)`
  width: 340px;
`;

const DescriptionItemWrapper = styled.div`
  max-width: 50%;
`;

const DescriptionItem = (diProps: { text: string | undefined }) => (
  <DescriptionItemWrapper>
    <Typography.Text ellipsis={{ tooltip: diProps.text || '-' }}>{diProps.text || '-'}</Typography.Text>
  </DescriptionItemWrapper>
);

interface ConfigurationCardProps {
  projectId: string;
  bundleId: string;
  id: number;
  name: string;
  description: string;
  lastVersionId: number | undefined;
  isLoading?: boolean;
}

const ConfigurationCard = ({ isLoading = false, id, lastVersionId, description, name, projectId, bundleId }: ConfigurationCardProps) => {
  const permissions = usePermissions({ projectId });
  const navigate = useNavigate();
  const versions = useConfigurationVersions(projectId, bundleId, String(id));
  const deleteConfiguration = useDeleteConfiguration();
  // const lastVersion = versions.data?.find((release) => Number(release.idBundleRelease) === Number(lastVersionId))?.version || 'Not found';
  const lastVersion = versions.data?.find((release) => Number(release.idBundleRelease) === Number(lastVersionId));

  const lastReleaseVersion = lastVersion ? lastVersion.version : '-';
  const lastReleaseDate = lastVersion ? lastVersion.createdAt : '';

  const handleCardClick = () => {
    navigate(`/projects/${projectId}/project-software/configurations/${id}`);
  };

  const flexItemOptions = {
    justify: 'flex-start',
    gap: 4
  };

  const DeleteProjectConfigurationButtonWithConfirm = (
    <ConfirmationButton
      title={`Deleting ${name}`}
      caption="Delete"
      danger
      description="All deployment plans associated with this configuration will also be deleted."
      paragraphDescription={
        <Typography.Paragraph>
          Are you sure you want to delete the <Typography.Text strong>{name}</Typography.Text> configuration? Deletion cannot be undone.
        </Typography.Paragraph>
      }
      onOk={() => {
        deleteConfiguration.mutate([projectId.toString(), bundleId, String(id)]);
      }}
    />
  );

  return (
    <StyledCard
      id={`app-conf-card-${id}`}
      size="small"
      loading={isLoading}
      hoverable
      onClick={handleCardClick}
      title={
        <Link to={`/projects/${projectId}/project-software/configurations/${id}`}>
          <Typography.Text strong>{name}</Typography.Text>
        </Link>
      }
      extra={
        <StopEventPropagationWrapper>
          <ExpandableMenu textType id={`update-app-config-${id}`} icon={<MoreOutlined />} disabled={isLoading}>
            {permissions.engineeringSvc$updateProjectBundleConfiguration && (
              <AddEditConfiguration projectId={projectId.toString()} bundleId={bundleId} configId={id.toString()} />
            )}
            {permissions.engineeringSvc$deleteProjectBundleConfiguration && DeleteProjectConfigurationButtonWithConfirm}
          </ExpandableMenu>
        </StopEventPropagationWrapper>
      }
    >
      <Flex vertical gap={'0.6em'}>
        <Flex {...flexItemOptions}>
          <DescriptionItem text="Last version:" />
          <Typography.Text strong ellipsis={{ tooltip: { title: lastReleaseVersion, placement: 'bottom' } }}>
            {lastReleaseVersion}
          </Typography.Text>
        </Flex>
        <Flex {...flexItemOptions}>
          <DescriptionItem text="Release date:" />
          <Typography.Text strong ellipsis={{ tooltip: { title: lastReleaseDate, placement: 'bottom' } }}>
            {lastReleaseDate ? formatDate(new Date(lastReleaseDate)) : '-'}
          </Typography.Text>
        </Flex>
        <Flex {...flexItemOptions}>
          <DescriptionItem text="Description:" />
          <Typography.Text ellipsis={{ tooltip: { title: description, placement: 'bottom' } }}>{description}</Typography.Text>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

export default ConfigurationCard;
