import { useState, useEffect, useMemo } from 'react';
import { Skeleton, type TableColumnProps } from 'antd';
import { isEqual, uniqBy } from 'lodash';
import { useTools } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useTools';
import { EngineeringToolSelection } from '../../../../domain/engineeringToolsSelection';
import { useTableSearch } from '../../../shared/components/TableSearch';
import { Comparator } from '../../../../domain/extensions/comparison';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { TableVersionHelp } from '../../../shared/components/TableVersionHelp';
import { Project, Tool, ToolVersion } from '../../../../api/engineering/domain/types';
import { toolHash } from '../../utils/toolHash';
import { useScrollInto } from '../../../navigation/hooks/useScrollInto';
import Table from '../../../shared/components/Table/Table';
import { useSearchParameter } from '../../../navigation/hooks/useSearchParameter';
import styled from 'styled-components';
import { ToolActionsMenu } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemActionsMenu/ToolActionsMenu';
import { SoftwareItemSelection } from '../../../../domain';
import { ToolVersionDropdown } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersionSelection/ToolVersionDropdown';
import { ToolDetails } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemDetails/ToolDetails';
import { ToolVersions } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersions/ToolVersions';
import { softwareItemHash } from '../../../ProjectSoftware/ConfigurationDetails/utils/softwareItemHash';
import { usePermissions } from '../../../../contexts/session/hooks/usePermissions';
import { useTableFilter } from '../../../../contexts/shared/components/TableFilter';

export interface ToolsListProps {
  selected: EngineeringToolSelection[];
  initiallySelected: EngineeringToolSelection[];
  showBundleItemsOnly?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: EngineeringToolSelection[]) => any;
  project?: Project;
  loading?: boolean;
  hideCheckboxes?: boolean;
}

type SelectionMap = {
  [versionId: string]: EngineeringToolSelection;
};

type ToolWithMergedCategoryString = Tool & {
  categoriesString: string;
};

export const StyledTable = styled(Table)<{ bodyMaxHeight?: string }>`
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
` as typeof Table;

const ToolsList = (props: ToolsListProps) => {
  const [filteredTools, setFilteredTools] = useState([] as Tool[]);
  const tools = useTools();
  const [selectionMap, setSelectionMap] = useState({} as SelectionMap);
  const [initialSelectionMap, setInitialSelectionMap] = useState({} as SelectionMap);
  const [tablePage, setTablePage] = useSearchParameter('p', '1');
  const tablePageNumber = parseInt(tablePage || '1');
  const [pageSize, setPageSize] = useSearchParameter(`ps`, '10');
  const pageSizeNumber = parseInt(pageSize || '10');
  const [tableRef, setTableRef] = useState<any>();
  const [openDrawerToolDetailsIdParam, setOpenDrawerToolDetailsIdParam] = useSearchParameter('open_tool_details');
  const [openDrawerToolVersionIdParam, setOpenDrawerToolVersionIdParam] = useSearchParameter('open_tool_versions');
  const detailsDrawerTool = tools.data?.find((tool) => softwareItemHash(tool) === openDrawerToolDetailsIdParam) || null;
  const versionsDrawerTool = tools.data?.find((tool) => softwareItemHash(tool) === openDrawerToolVersionIdParam) || null;
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    toolId: versionsDrawerTool?.id.toString()
  });
  const canGetVersions = permissions.engineeringSvc$getToolVersions || permissions.engineeringSvc$getToolVersion$specific().length > 0;

  const scrollHash = location.hash?.replace('#', '.');
  useScrollInto(scrollHash, tableRef, tableRef);

  const data = useMemo(() => {
    const ret: Tool[] = [];
    if (tools.data) {
      ret.push(...tools.data);
    }
    ret.push(...props.initiallySelected.map((t) => ({ ...t.engineeringTool, latestVersion: t.version })));
    // Remove duplicates
    return uniqBy(ret, (r) => `${r.id}`);
  }, [tools.data, props.initiallySelected]);

  const nameSearch = useTableSearch({ searchValueProvider: 'name', searchParamId: 'name' });

  const categoryFilter = useMemo(
    () =>
      uniqBy((tools.data ?? []).map((t) => t.category).flat(), (tc) => tc.id)
        ?.filter((c) => c.name !== '' && c.id !== undefined)
        .sort((a, b) => Comparator.lexicographicalComparison(a.name || '', b.name || ''))
        .map((o) => {
          return { text: o.name!, value: o.id!.toString() };
        }) || [],
    [tools.data]
  );
  const categoryFilterProps = useTableFilter<Tool>({
    key: 'category-filter',
    onFilter: (tool: Tool, value: string) => {
      return tool.category.map((c) => c.id.toString()).includes(value);
    },
    values: categoryFilter,
    searchParamId: 'category'
  });

  const isLoading = tools.isLoading || !!props.loading;
  const isSuccess = tools.isSuccess && !props.loading;

  const loadingDebounced = useDebounce(isLoading && !isSuccess, 100, true);
  const successDebounced = (useDebounce(isSuccess, 100) || isSuccess) && !loadingDebounced;

  const updateSelectionMapForTool = (t: EngineeringToolSelection, map: SelectionMap) => {
    if (map[t.engineeringTool.id!.toString()]) {
      map[t.engineeringTool.id!.toString()].version = t.version;
    }
  };

  const handleCloseDetailsDrawer = () => {
    setOpenDrawerToolDetailsIdParam('');
  };

  const handleCloseVersionDrawer = () => {
    setOpenDrawerToolVersionIdParam('');
  };

  const handleOpenVersionDrawer = (tool: Tool) => {
    setOpenDrawerToolVersionIdParam(softwareItemHash(tool));
  };

  const handleOpenDetailsDrawer = (tool: Tool) => {
    setOpenDrawerToolDetailsIdParam(softwareItemHash(tool));
  };

  const toolsWithCategoryStrings: ToolWithMergedCategoryString[] = useMemo(() => {
    return filteredTools
      .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
      .map((t) => {
        const toolWithCategoryString: ToolWithMergedCategoryString = {
          ...t,
          categoriesString: (t.category || [])
            .map((c) => c.name || '')
            .filter((n) => n !== '')
            .join(', ')
        };
        return toolWithCategoryString;
      });
  }, [filteredTools]);

  useEffect(() => {
    if (!props.onDirty) {
      return;
    }
    props.onDirty(!isEqual(props.selected.map((t) => t.version.idToolVersion).sort(), props.initiallySelected.map((t) => t.version.idToolVersion).sort()));
  }, [props.selected, props.initiallySelected, props]);

  useEffect(() => {
    const newSelectionMap: SelectionMap = {};
    if (data) {
      data.forEach((t) => {
        if (t.latestVersion) {
          newSelectionMap[t.id.toString()] = {
            engineeringTool: t,
            version: t.latestVersion
          };
        }
      });
    }
    const newInitialSelectedMap: SelectionMap = {};

    props.initiallySelected.forEach((t) => {
      if (newSelectionMap[t.engineeringTool.id.toString()]) {
        newSelectionMap[t.engineeringTool.id.toString()].version = t.version;
      }
    });
    props.initiallySelected.forEach((t) => {
      newInitialSelectedMap[t.engineeringTool.id.toString()] = t;
    });

    props.initiallySelected.forEach((t) => updateSelectionMapForTool(t, newInitialSelectedMap));
    setInitialSelectionMap(newInitialSelectedMap);

    props.selected.forEach((t) => updateSelectionMapForTool(t, newSelectionMap));
    setSelectionMap(newSelectionMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props.initiallySelected, props.selected]);

  useEffect(() => {
    if (data) {
      setFilteredTools(
        data.filter(
          (t) =>
            props.initiallySelected.find((st) => st.engineeringTool.id === t.id) ||
            props.selected.find((st) => st.engineeringTool.id === t.id) ||
            !props.showBundleItemsOnly
        )
      );
    }
  }, [data, props.selected, props.showBundleItemsOnly, props.initiallySelected]);

  const onSelectionUpdate = (selection: EngineeringToolSelection[]) => {
    props.onSelect(selection.filter((s) => s));
  };

  const updateSelectedToolVersion = (sa: Tool, sv: ToolVersion) => {
    const newSelectionMap = { ...selectionMap };
    newSelectionMap[sa.id!.toString()] = {
      engineeringTool: sa,
      version: sv
    };
    if (props.selected.find((swa) => swa.engineeringTool.id === sa.id)) {
      const selected = props.selected.map((sr) => newSelectionMap[sr.engineeringTool.id!.toString()]);
      onSelectionUpdate(selected);
    }
    setSelectionMap({ ...newSelectionMap });
  };

  const rowSelection = {
    selectedRowKeys: props.selected.map((t) => toolHash(t.engineeringTool)),
    onChange: (selectedRowKeys: any, selectedRows: Tool[]) => {
      const selected = selectedRows.map((sr) => selectionMap[sr.id!.toString()]);
      onSelectionUpdate(selected);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name
    })
  };

  const columns: TableColumnProps<Tool>[] = [
    {
      title: 'Name',
      fixed: 'left',
      ...nameSearch,
      sorter: (a: Tool, b: Tool) => Comparator.lexicographicalComparison(a.name, b.name),
      render: (tool: Tool) => tool.name
    },
    {
      title: 'Categories',
      key: 'categories',
      width: '25%',
      ...categoryFilterProps,
      render: (t: ToolWithMergedCategoryString) => {
        return t.categoriesString;
      }
    },
    {
      title: <TableVersionHelp />,
      key: 'version',
      render: (t: Tool) => {
        const selectedVersion = selectionMap[t.id!.toString()].version;
        const initialSelectedVersion = props.project ? initialSelectionMap[t.id!.toString()]?.version : undefined;
        return (
          <ToolVersionDropdown
            engineeringToolId={t.id!.toString()}
            selectedVersion={selectedVersion}
            initiallySelectedVersion={initialSelectedVersion}
            onSelected={(version) => {
              updateSelectedToolVersion(t, version);
            }}
          />
        );
      },
      width: '30%'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      render: (t: Tool) => {
        let selection: SoftwareItemSelection | undefined;
        if (Object.keys(selectionMap).includes(t.id!.toString())) {
          const selectedVersion = selectionMap[t.id!.toString()].version;
          selection = { softwareItem: t, version: selectedVersion };
        }
        return (
          <ToolActionsMenu
            onOpenDetailsDrawer={handleOpenDetailsDrawer}
            onOpenVersionDrawer={handleOpenVersionDrawer}
            tool={t as Tool}
            project={props.project}
            selection={selection}
          />
        );
      },
      width: '10%'
    }
  ];

  const table = (
    <div ref={setTableRef}>
      <StyledTable
        rowSelection={
          props.hideCheckboxes || toolsWithCategoryStrings.length < 1
            ? undefined
            : {
                type: 'checkbox',
                ...rowSelection,
                columnWidth: 48
              }
        }
        sticky={{
          offsetHeader: 0
        }}
        columns={columns}
        scroll={{ x: true }}
        rowKey={toolHash}
        rowClassName={toolHash}
        dataSource={toolsWithCategoryStrings}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: pageSizeNumber,
          current: tablePageNumber,
          onChange: (p) => {
            setTablePage(p.toString());
          },
          onShowSizeChange: (c, s) => {
            setPageSize(s.toString());
          },
          pageSizeOptions: ['10', '20', '50', '100']
        }}
      />
    </div>
  );

  const result = (
    <>
      {permissions.webui$showComponentDetails && <ToolDetails onClose={handleCloseDetailsDrawer} open={!!detailsDrawerTool} tool={detailsDrawerTool} />}
      {canGetVersions && (
        <ToolVersions
          onClose={handleCloseVersionDrawer}
          open={!!versionsDrawerTool}
          tool={versionsDrawerTool}
          bundleVersion={!!versionsDrawerTool ? initialSelectionMap[versionsDrawerTool.id.toString()]?.version : undefined}
        />
      )}
      {loadingDebounced && <Skeleton active />}
      {successDebounced && <div>{table}</div>}
    </>
  );

  return result;
};

export default ToolsList;
