import { Col, Radio, Row, Flex } from 'antd';
import { useRef } from 'react';
import { useSearchParameter } from '../../../../navigation/hooks/useSearchParameter';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';

import type { Project, Tool, ToolVersion } from '../../../../../api/engineering/domain/types';
import { ToolVersionsList } from './components/SoftwareItemVersionsList/ToolVersionsList';
import { ToolUsageList } from './components/SoftwareItemUsageList/ToolUsageList';
import { AddToolVersion } from './components/AddSoftwareItemVersion/AddToolVersion';
import { HideContainer } from '../../../../../contexts/shared/components/HideContainer';

interface ToolVersionsProps {
  tool: Tool | null;
  onClose: () => void;
  open: boolean;
  bundleVersion?: ToolVersion;
}

export const ToolVersions = (props: ToolVersionsProps) => {
  const { tool, onClose, open } = props;
  const projectRef = useRef<Project | undefined>(undefined);
  const [usageViewSearchParam, setUsageViewSearchParam] = useSearchParameter('view');
  const permissions = usePermissions({ toolId: tool?.id.toString() });
  const hasComparePermissions = permissions.engineeringSvc$getToolUsage;

  const showUsageRadio = hasComparePermissions;

  const tab = usageViewSearchParam || 'versions';
  const options = [
    { label: 'Version details', value: 'versions' },
    { label: 'Project integration', value: 'usage' }
  ];

  return (
    <ShiftedDrawer
      width={tab === 'usage' ? '90%' : undefined}
      onClose={onClose}
      open={open}
      title={`Versions overview:  ${tool?.name ?? ''}`}
      extra={<>{!!tool && <AddToolVersion key="add" tool={tool} detailView />}</>}
    >
      {!!tool && (
        <Flex gap={'1em'} vertical>
          {showUsageRadio ? (
            <Radio.Group
              style={{ marginTop: 8 }}
              options={options}
              onChange={(e) => setUsageViewSearchParam(e.target.value)}
              value={tab}
              optionType="button"
              buttonStyle="solid"
            />
          ) : null}

          <Row>
            <Col span={24}>
              {/* Use hide container here since we don't want version list to be removed from dom when switching to usage
            this is important to e.g., keep the filter parameters */}
              <HideContainer hidden={tab !== 'versions'}>
                <ToolVersionsList engineeringTool={tool} project={projectRef.current} bundleVersion={props.bundleVersion} />
              </HideContainer>
              <HideContainer hidden={tab !== 'usage'}>
                <ToolUsageList engineeringTool={tool} bundleVersion={props.bundleVersion} />
              </HideContainer>
            </Col>
          </Row>
        </Flex>
      )}
    </ShiftedDrawer>
  );
};
